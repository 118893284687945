import * as React from 'react';
import { useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage } from '@src/pages/withBasePage';
import {
  CssProps,
  dispatchActionAppUpdateState,
  SantaAppState,
  UserAccountInfoState,
} from '@src/state';
import { ColumnStart, withBase } from '@lub0v/ui-components';
import { santaClient } from '@src/state/client';
import { AccountNotificationInfo, AccountType, UserAccountInfo } from '@lub0v/santa-utils';
import { SwitchPanel } from '@src/components';

interface AccountPageProps extends BasePageProps {
  account: UserAccountInfoState;
  css: CssProps;
}

function NotificationsSettingsComponent(props: AccountPageProps) {
  const vkWidget = useRef(null);

  const setAccountNotification = async (
    externalId: string,
    enabled: boolean,
    type: AccountType,
  ) => {
    const account: UserAccountInfo = (
      await santaClient.enableAccountNotification({
        externalId,
        enabled,
        type,
      })
    ).data;
    dispatchActionAppUpdateState({ account: { account } });
  };

  useEffect(() => {
    // @ts-ignore
    if (!window.VK || !window.VK.Widgets) {
      return;
    }
    // @ts-ignore
    window.VK.Widgets.AllowMessagesFromCommunity(
      'vk_allow_messages_from_community',
      { height: 30 },
      210307217,
    );

    // @ts-ignore
    window.VK.Observer.subscribe('widgets.allowMessagesFromCommunity.allowed', async (userId) => {
      await setAccountNotification(userId, true, AccountType.VK);
    });
    // @ts-ignore
    window.VK.Observer.subscribe('widgets.allowMessagesFromCommunity.denied', async (userId) => {
      await setAccountNotification(userId, false, AccountType.VK);
    });
    setTimeout(() => {
      vkWidget.current.style.width = '6rem';
    }, 10);
  }, []);

  const emailNotification: AccountNotificationInfo = props.account.account.accountNotifications.find(
    (a) => a.accountType === AccountType.EMAIL,
  );
  const vkNotification: AccountNotificationInfo = props.account.account.accountNotifications.find(
    (a) => a.accountType === AccountType.VK,
  );
  const tgNotification: AccountNotificationInfo = props.account.account.accountNotifications.find(
    (a) => a.accountType === AccountType.TG,
  );
  return (
    <>
      <ColumnStart translator={props.translator}>
        <SwitchPanel
          style={{ marginBottom: 0 }}
          title="account_notifications_email_title"
          hint="account_notifications_email_hint"
          value={emailNotification}
          onChange={async () => {
            await setAccountNotification(
              props.account.account.email,
              !emailNotification,
              AccountType.EMAIL,
            );
          }}
        />
        <SwitchPanel
          style={{ marginBottom: 0 }}
          title="account_notifications_vk_title"
          hint="account_notifications_vk_hint"
          value={vkNotification}
        >
          <div
            ref={vkWidget}
            id="vk_allow_messages_from_community"
            style={{ position: 'absolute', right: '0', top: '1.3rem', opacity: 0 }}
          />
        </SwitchPanel>
        <SwitchPanel
          style={{ marginBottom: 0 }}
          title="account_notifications_telegram_title"
          hint="account_notifications_telegram_hint"
          value={tgNotification}
          onChange={async () => {
            if (tgNotification) {
              await setAccountNotification(tgNotification.externalId, false, AccountType.TG);
            } else {
              window.open(
                // @ts-ignore
                `https://t.me/${window.TelegramBotName}?start=${props.account.account.verificationKey}`,
                '_blank',
              );
            }
          }}
        />
      </ColumnStart>
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    session: state.session,
    account: state.account,
    css: state.css,
  };
}

export const NotificationsSettings = withBase(
  connect(mapStateToProps)(NotificationsSettingsComponent),
);
